<div id="checkout" class="h-full bg-gray-100 overflow-y-auto">
  <div
    class="w-full px-5 py-7 sm:px-[10vw] md:max-w-max md:px-10 md:py-14 md:mx-auto xl:px-[10vw]"
    [ngClass]="{
      flex: !isRainout,
      'flex-col': !isRainout
    }">
    <div class="mb-7 text-center md:mb-5">
      <h1 class="text-4xl mb-1 md:mb-3 lg:mb-0">Your cart</h1>
      <app-timer
        (transactionExpired)="onTransactionExpired()"
        [createdTime]="this.transaction()!.transaction.createdAt"></app-timer>
    </div>

    <div class="w-full flex flex-wrap">
      <div class="w-full flex flex-wrap md:-m-2 md:h-1/2 lg:-m-5">
        <div
          class="w-full mb-5 md:p-2 md:h-full md:w-8/12 lg:p-5 lg:w-6/12 lg:mb-10">
          <app-cart [transaction]="transaction()!" />
        </div>

        <div
          class="w-full mb-5 md:p-2 md:h-full md:w-4/12 lg:p-5 lg:w-6/12 lg:mb-10">
          <app-group-outing
            [transaction]="transaction()!"
            [upgradeSelected]="upgradeSelected"
            [totalTransactionSeats]="totalTransactionSeats"
            (changeUpgrade)="onChangeUpgrade($event)" />
        </div>
      </div>

      <div class="w-full flex flex-wrap md:-m-2 md:h-1/2 lg:-m-5">
        <!-- RAIN OUT -->
        <div
          class="w-full mb-5 md:p-2 md:h-full md:w-6/12 lg:p-5"
          [hidden]="!isRainout">
          <app-rainout-info
            [transaction]="transaction()!"
            [totalTransactionSeats]="seats.length"
            [upgradeSelected]="upgradeSelected"
            (changeUpgrade)="onChangeUpgrade($event)"
            (rainoutChange)="onRainoutChange($event)" />
        </div>

        <!-- SUMMARY -->
        <div
          class="w-full mb-5 md:p-2 md:h-full lg:p-5"
          [ngClass]="isRainout ? 'md:w-6/12' : 'md:w-8/12 md:ml-auto'">
          <app-summary
            [transaction]="transaction()!"
            [rainoutValue]="rainoutValue" />
        </div>
      </div>
    </div>

    <div
      class="mt-7 flex flex-col-reverse items-center md:flex-row md:items-stretch md:justify-between">
      <button
        class="btn-main-reverse h-12 disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none"
        (click)="restartTransaction()">
        Restart
      </button>
      <button
        data-cy="go-to-payment-button"
        class="btn-black bg-red disabled:bg-gray-600 mb-5 md:mb-0 disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none"
        (click)="proceedToPayment()"
        [disabled]="
          configurationService.configuration.rainout &&
          rainoutValue === null &&
          eventDate - nowDate > deadlineInMilliseconds
        "
        [tooltip]="
          configurationService.configuration.rainout &&
          rainoutValue === null &&
          eventDate - nowDate > deadlineInMilliseconds
            ? 'Please select a Rainout Insurance option.'
            : ''
        "
        placement="top"
        triggers="pointerenter:pointerout">
        Proceed to Payment
        <span class="ml-4 pointer-events-none"
          ><img
            ngSrc="/assets/img/arrow-right-white-icon.svg"
            width="8"
            height="14"
            [priority]="false"
            alt="Arrow right icon"
        /></span>
      </button>
    </div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  Input,
  Output,
} from '@angular/core';
import { UpsellsService } from '../../../select-seat/viewer/topbar/upsells/upsells.service';
import { TransactionWrapper, Upgrade, Upsell } from '@core/models';
import { CommonModule, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-group-outing',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './group-outing.component.html',
  styleUrl: './group-outing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupOutingComponent {
  @Output() changeUpgrade = new EventEmitter<HTMLInputElement>();
  private upsellsService = inject(UpsellsService);
  totalTransactionSeats = input.required<number>();
  transaction = input.required<TransactionWrapper>();
  upgradeSelected = input.required<Upgrade | null>();

  get acquiredUpsells(): Upsell[] {
    return this.upsellsService.acquiredUpsells;
  }

  onChangeUpgrade(radio: HTMLInputElement) {
    this.changeUpgrade.emit(radio);
  }
}

<div class="box-main mb-5 box-responsive md:h-48 md:flex">
  <div
    class="px-6 py-4 bg-rain bg-cover flex items-center rounded-2.5xl justify-center md:w-1/3">
    <h3 class="font-bold flex text-base md:text-lg">
      <span class="mr-1 text-red">*</span> Protect your group outing
    </h3>
  </div>
  @if (showRainoutInfo()) {
    <!-- Rainout Info -->
    <div
      class="px-6 py-4 flex flex-wrap justify-center md:flex-nowrap md:justify-stretch md:w-2/3 md:items-center">
      <div class="mb-4 md:h-full md:mr-4 md:overflow-y-auto">
        <p class="font-bold mb-1">About</p>
        <p>
          The
          {{ configurationService.configuration.clientName }} offer group ticket
          buyers the opportunity to purchase Group Rainout Insurance (ROI) to
          protect against the weather-related postponements of games by the
          {{ configurationService.configuration.clientName }} or Major League
          Baseball. In the event your group ticket game is postponed due to
          weather-related reasons, you will be eligible for a full-refund (less
          the cost of ROI). Rainout Insurance may be purchased for 10 percent of
          the total ticket package price. For more information on Group Rainout
          Insurance please
          <a
            class="underline text-main-light"
            [href]="configurationService.configuration.rainoutInsuranceUrl"
            target="_blank"
            >click here</a
          >.
        </p>
      </div>
      <button
        class="btn-white w-20 my-2 text-main-dark border border-main-dark"
        (click)="showRainoutInfo.set(false)">
        Close
      </button>
    </div>
  } @else {
    <!-- Rainout Choose -->
    <div
      class="px-6 py-4 flex flex-wrap justify-center md:flex-nowrap md:justify-start md:w-2/3 md:items-center">
      <div class="mx-8 mb-4 text-center md:mx-0 md:mb-0 md:text-left md:mr-4">
        @if (eventDate() - nowDate > deadlineInMilliseconds) {
          <h4 class="mb-2 font-normal text-sm md:text-base">
            Would you like to add Rainout Insurance to protect your outing?
          </h4>
        }
        @if (eventDate() - nowDate > deadlineInMilliseconds) {
          <div class="flex items-center justify-center md:justify-stretch">
            <label for="yes" class="ml-2 mr-6">
              <input
                type="radio"
                name="rain-out"
                id="yes"
                [value]="true"
                [(ngModel)]="
                  transaction()!.transaction.tdcTransaction!.hasInsuranceRainout
                "
                (ngModelChange)="onRainoutChange($event)" />
              Yes</label
            >

            <label for="no" class="ml-2">
              <input
                type="radio"
                name="rain-out"
                id="no"
                [value]="false"
                [(ngModel)]="
                  transaction()!.transaction.tdcTransaction!.hasInsuranceRainout
                "
                (ngModelChange)="onRainoutChange($event)" />
              No</label
            >
          </div>
        } @else {
          <h5 class="font-normal text-gray-400">
            Rainout Insurance is only available 21+ days before the game.
          </h5>
        }
      </div>
      <button
        class="btn-main w-20 my-2 bg-main-dark border border-white"
        (click)="showRainoutInfo.set(true)">
        +Info
      </button>
    </div>
  }
</div>

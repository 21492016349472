import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  signal,
} from '@angular/core';
import { TransactionWrapper, Upsell } from '@core/models';
import { environment } from '@environments/environment';
import { UpsellsService } from '../../../select-seat/viewer/topbar/upsells/upsells.service';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent {
  private upsellsService = inject(UpsellsService);
  rainoutValue = input.required<boolean>();
  transaction = input.required<TransactionWrapper>();
  isProduction = signal(environment.production);

  get acquiredUpsells(): Upsell[] {
    return this.upsellsService.acquiredUpsells;
  }
}

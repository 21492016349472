import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import {
  PriceWithQuantity,
  Seat,
  SeatsBySection,
  TransactionWrapper,
} from '@core/models';
import { UtilitiesService } from '@core/services';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartComponent implements OnInit {
  utilitiesService = inject(UtilitiesService);
  transaction = input.required<TransactionWrapper>();
  private seats = signal<Seat[]>([]);
  seatsByPrice = computed<PriceWithQuantity[]>(() => this.initSeatsByPrice());
  seatsForTable = computed<SeatsBySection[]>(() => this.initSeatsForTable());

  ngOnInit(): void {
    this.initComponent();
  }

  private initComponent() {
    const transaction = this.transaction();

    if (!transaction) {
      return;
    }

    const seats: Seat[] = [];
    if (transaction?.transaction?.seatsPrices) {
      const seatsPricesEntries = Object.entries(
        transaction?.transaction?.seatsPrices
      );

      for (const [mmcId, price] of seatsPricesEntries) {
        let seat = this.utilitiesService.splittedSeatInfoFromId(mmcId) as Seat;
        seat.price = price.value;
        seats.push(seat);
      }
    }

    this.seats.set(seats);
  }

  private initSeatsByPrice() {
    const pricesHolder: {
      [key: string]: { price: Seat['price']; quantity: number };
    } = {};
    for (const seat of this.seats()) {
      if (!pricesHolder[seat.price]) {
        pricesHolder[seat.price] = { price: seat.price, quantity: 1 };
      } else {
        pricesHolder[seat.price].quantity += 1;
      }
    }

    return Object.values(pricesHolder) as [];
  }

  private initSeatsForTable() {
    const seatsBySections = this.utilitiesService.distributeSeatsBySection(
      this.seats()
    );
    const sortedSeatsBySectionArray =
      this.utilitiesService.sortSectionsBySeatsQuantity(seatsBySections);
    const sortedSeatsBySectionAndRowArray =
      this.utilitiesService.addRowDistribution(sortedSeatsBySectionArray);
    sortedSeatsBySectionAndRowArray.forEach(section => {
      section.rows = Object.values(section.rows || []);
    });
    return this.utilitiesService.addRangeSeats(sortedSeatsBySectionAndRowArray);
  }
}

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TimerComponent } from '@core/components/timer/timer.component';
import { ModalsService } from '@core/modals/modals.service';
import {
  Seat,
  TransactionModel,
  TransactionWrapper,
  Upgrade,
  Upsell,
} from '@core/models';
import {
  ApiException,
  ConfigurationService,
  UtilitiesService,
} from '@core/services';
import { environment } from '@environments/environment';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Subscription } from 'rxjs';
import { UpsellsService } from '../select-seat/viewer/topbar/upsells/upsells.service';
import { CartComponent } from './components/cart/cart.component';
import { GroupOutingComponent } from './components/group-outing/group-outing.component';
import { RainoutInfoComponent } from './components/rainout-info/rainout-info.component';
import { SummaryComponent } from './components/summary/summary.component';
import { ConnectionService } from './services/connection.service';

@Component({
  imports: [
    CommonModule,
    FormsModule,
    NgOptimizedImage,
    RainoutInfoComponent,
    TimerComponent,
    TooltipModule,
    CartComponent,
    GroupOutingComponent,
    SummaryComponent,
  ],
  selector: 'app-checkout',
  standalone: true,
  styleUrls: ['./checkout.component.scss'],
  templateUrl: './checkout.component.html',
})
export class CheckoutComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private connectionService = inject(ConnectionService);
  private activatedRoute = inject(ActivatedRoute);
  private modalsService = inject(ModalsService);
  public configurationService = inject(ConfigurationService);
  private upsellsService = inject(UpsellsService);
  private utilitiesService = inject(UtilitiesService);
  deadlineInMilliseconds: number;
  eventDate: number;
  isRainout: boolean;
  nowDate: number = Date.now();
  seats: Seat[];
  selectedBuyerTypeId: number;
  subscription: Subscription[] = [];
  totalTransactionSeats: number;
  transactionId: string;
  upgradeSelected: Upgrade | null;
  transaction = signal<TransactionWrapper | null>(null);

  get rainoutValue(): boolean {
    const transaction = this.transaction();
    if (!transaction) {
      return false;
    } else {
      return !!transaction?.transaction?.tdcTransaction?.hasInsuranceRainout;
    }
  }

  set rainoutValue(value) {
    const transaction = this.transaction();

    if (!transaction) {
      return;
    }

    Object.assign(
      (transaction.transaction as Required<TransactionModel>).tdcTransaction,
      { hasInsuranceRainout: Boolean(value) }
    );
  }

  ngOnInit(): void {
    const transaction = this.activatedRoute.snapshot.data[
      'transaction'
    ] as TransactionWrapper;
    this.transactionId = this.activatedRoute.snapshot.params['id'];
    this.isRainout = this.configurationService.configuration.rainout;

    this.connectionService.token =
      this.activatedRoute.snapshot.queryParams['token'];

    this.initializeComponent(transaction);
  }

  initializeComponent(transaction: TransactionWrapper) {
    if (!transaction) {
      const modalData = {
        title: 'ERROR',
        content: 'An Error occurred while trying to get the Transaction.',
        acceptBtnName: 'CLOSE',
        acceptFunction: this.onTransactionExpiredAction.bind(this),
      };
      this.modalsService.openModal(modalData);
      return;
    }
    this.transaction.set(transaction);

    this.onTransactionChange(); // calculate seats by row and so on
    // the first time put the value to null to force the user to select one
    if (!transaction.transaction.tdcTransaction?.hasInsuranceRainout) {
      (
        transaction.transaction as Required<TransactionModel>
      ).tdcTransaction.hasInsuranceRainout = false;
    }
    // Rainout deadline
    if (transaction.transaction.event?.date) {
      this.eventDate = new Date(transaction.transaction.event.date).getTime();
    }
    this.deadlineInMilliseconds = 21 * 24 * 60 * 60 * 1000; // 21 days

    // Upsells
    this.upsellsService.upsells = transaction.transaction.event
      ?.upsells as Upsell[];
    this.upsellsService.checkCartSeatsForUpsellsList(
      this.totalTransactionSeats
    );
  }

  onRainoutChange(rainoutValue: boolean) {
    // if rainout no and we have rainout price
    const transaction = this.transaction();
    if (
      !rainoutValue &&
      (transaction?.transaction as Required<TransactionModel>).tdcTransaction
        ?.insuranceRainoutPrice
    ) {
      const subscription = this.connectionService
        .deleteRainoutInsuranceToTransaction(this.transactionId)
        .subscribe({
          next: data => {
            if (transaction?.transaction) {
              transaction.transaction = data.data as TransactionModel;
              this.transaction.set(transaction);
              this.onTransactionChange();
            }
          },
          error: error => {
            this.rainoutValue = true;
            console.error(error);
            const modalData = {
              title: 'ERROR',
              content:
                'An Error occurred while trying to delete Rainout Insurance, please try again.',
              acceptBtnName: 'CLOSE',
            };
            // If there's a custom api error.
            if (error instanceof ApiException) {
              modalData.content = error.message;
            }
            this.modalsService.openModal(modalData);
          },
        });

      this.subscription.push(subscription);
    }
    if (rainoutValue) {
      const subscription = this.connectionService
        .addRainoutInsuranceToTransaction(this.transactionId)
        .subscribe({
          next: data => {
            transaction!.transaction = data.data as TransactionModel;
            this.transaction.set(transaction);
          },
          error: error => {
            this.rainoutValue = false;
            console.error(error);
            const modalData = {
              title: 'ERROR',
              content:
                'An Error occurred while trying to add Rainout Insurance, please try again.',
              acceptBtnName: 'CLOSE',
            };
            // If there's a custom api error.
            if (error instanceof ApiException) {
              modalData.content = error.message;
            }
            this.modalsService.openModal(modalData);
          },
        });

      this.subscription.push(subscription);
    }
  }

  proceedToPayment() {
    this.router.navigate(['/', 'payment', this.transactionId], {
      queryParams: { token: this.connectionService.token },
    });
  }

  onChangeUpgrade(radio: HTMLInputElement) {
    const transaction = this.transaction();
    if (!transaction) {
      return;
    }

    let buyerTypeId = parseInt(radio.value, 10);
    let actualBuyerType = transaction.transaction.buyerTypeId;
    if (actualBuyerType !== buyerTypeId) {
      let buyerTypeName = '';
      radio.checked = true;
      // get the buyer type name to send with buyer type id
      if (transaction.transaction.event?.upgrades?.values()) {
        for (const buyerTypeInfo of transaction.transaction.event.upgrades.values()) {
          if (buyerTypeInfo.buyerTypeId == buyerTypeId) {
            buyerTypeName = buyerTypeInfo.buyerTypeName as string;
          }
        }
      }
      // put the same rainout insurance value again to take into account the user has not selected yes or no
      const previousRainoutValue = (
        transaction.transaction as Required<TransactionModel>
      ).tdcTransaction.hasInsuranceRainout;
      this.editBuyerType(previousRainoutValue, buyerTypeId, buyerTypeName);
    } else {
      const defaultBuyerTypeId = (
        transaction.transaction as Required<TransactionModel>
      ).event.defaultBuyerType;
      let defaultBuyerTypeName = (
        transaction.transaction as Required<TransactionModel>
      ).event.defaultBuyerTypeName;
      const previousRainoutValue = (
        transaction.transaction as Required<TransactionModel>
      ).tdcTransaction.hasInsuranceRainout;
      // request to api
      this.editBuyerType(
        previousRainoutValue,
        defaultBuyerTypeId as number,
        defaultBuyerTypeName as string
      );
      this.selectedBuyerTypeId = 0;
      radio.checked = false;
    }

    return;
  }

  restartTransaction() {
    const subscription = this.connectionService
      .deleteTransaction(this.transactionId)
      .subscribe({
        next: response => {
          this.goSeatSelection();
        },
        error: error => {
          // no need for a modal here
          this.goSeatSelection();
        },
      });
    this.subscription.push(subscription);
  }

  goSeatSelection() {
    const transaction = this.transaction();

    let eventId: number = 0;
    if (transaction) {
      eventId = (transaction.transaction as Required<TransactionModel>).event
        .pvEventId;
    }
    this.router.navigate(['seat-selection'], {
      queryParams: { event: eventId },
    });
  }

  onTransactionExpired() {
    const modalData = {
      title: 'Transaction expired',
      content: 'The transaction has expired, please try again.',
      acceptBtnName: 'CLOSE',
      acceptFunction: this.onTransactionExpiredAction.bind(this),
    };
    this.modalsService.openModal(modalData);
  }

  onTransactionExpiredAction() {
    const subscription = this.connectionService
      .deleteTransaction(this.transactionId)
      .subscribe({
        next: response => {
          this.goSeatSelection();
        },
        error: error => {
          // no need for a modal here
          this.goSeatSelection();
        },
      });
    this.subscription.push(subscription);
  }
  // executed when we retrieve the transaction again
  onTransactionChange() {
    //! Provisional data for checkout templating
    const transaction = this.transaction();
    if (!transaction) {
      return;
    }

    this.seats = [];
    this.upgradeSelected = null;
    // create structure for frontend
    // TODO: change this access to mmcID
    if (transaction.transaction.seatsPrices) {
      const seatsPricesEntries = Object.entries(
        transaction.transaction.seatsPrices
      );

      for (const [mmcId, price] of seatsPricesEntries) {
        let seat = this.utilitiesService.splittedSeatInfoFromId(mmcId) as Seat;
        seat.price = price.value;
        this.seats.push(seat);
      }
    }

    this.totalTransactionSeats = this.seats.length;
    if (transaction.transaction.event?.upgrades?.values()) {
      for (const upgradeInfo of transaction.transaction.event.upgrades.values()) {
        if (upgradeInfo.buyerTypeId == transaction.transaction.buyerTypeId) {
          this.upgradeSelected = upgradeInfo;
        }
      }
    }
  }

  private editBuyerType(
    previousRainoutValue: boolean,
    buyerTypeId: number,
    buyerTypeName: string
  ) {
    const subscription = this.connectionService
      .editTransactionBuyerType(this.transactionId, buyerTypeId, buyerTypeName)
      .subscribe({
        next: data => {
          const transaction = this.transaction();
          if (!transaction) {
            return;
          }
          transaction.transaction = data.data as TransactionModel;
          // put the same rainout insurance value again to take into account the user has not selected yes or no
          Object.assign(
            (transaction.transaction as Required<TransactionModel>)
              .tdcTransaction,
            { hasInsuranceRainout: previousRainoutValue }
          );
          this.transaction.set(transaction);
          this.onTransactionChange();
        },
        error: error => {
          console.error(error);
          const modalData = {
            title: 'ERROR',
            content: 'An Error occurred while trying to change upgrades',
            acceptBtnName: 'CLOSE',
            acceptFunction: () => undefined,
          };
          // If there's a custom api error.
          if (error instanceof ApiException) {
            modalData.content = error.message;

            modalData['acceptFunction'] = () => {
              this.restartTransaction();
            };
          }
          this.modalsService.openModal(modalData);
        },
      });

    this.subscription.push(subscription);
  }

  getEnvironment(): boolean {
    return environment.production;
  }

  ngOnDestroy(): void {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}

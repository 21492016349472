import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TransactionModel, TransactionWrapper, Upgrade } from '@core/models';
import { ConfigurationService } from '@core/services/configuration.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  imports: [CommonModule, FormsModule, TooltipModule],
  selector: 'app-rainout-info',
  standalone: true,
  templateUrl: './rainout-info.component.html',
  styleUrl: './rainout-info.component.scss',
})
export class RainoutInfoComponent implements OnInit {
  configurationService = inject(ConfigurationService);
  changeUpgrade = output<HTMLInputElement>();
  rainoutChange = output<boolean>();
  transaction = input.required<TransactionWrapper>();
  upgradeSelected = input.required<Upgrade | null>();
  deadlineInMilliseconds = 21 * 24 * 60 * 60 * 1000; // 21 days
  nowDate = Date.now();
  eventDate = signal<number>(0);
  totalTransactionSeats = input.required<number>();
  rainoutValue = computed(() => {
    const transaction = this.transaction();

    if (!transaction) {
      return false;
    }

    return (transaction.transaction as Required<TransactionModel>)
      .tdcTransaction?.hasInsuranceRainout;
  });
  showRainoutInfo = signal(false);

  ngOnInit(): void {
    if (this.transaction().transaction.event?.date) {
      this.eventDate.set(
        new Date(this.transaction().transaction.event?.date as string).getTime()
      );
    }
  }

  onChangeUpgrade(radio: HTMLInputElement) {
    this.changeUpgrade.emit(radio);
  }

  onRainoutChange(rainoutValue: boolean) {
    this.rainoutChange.emit(rainoutValue);
  }
}

<!-- Group outing -->
<div class="box-white h-full">
  <div class="box-header-center md:box-header">
    <h3 class="font-bold h-full md:ml-5 lg:10">Enhance your group outing</h3>
  </div>
  <div class="box-body flex flex-wrap">
    <!-- Upgrades -->
    @for (
      upgrade of transaction().transaction.event?.upgrades;
      track upgrade.id
    ) {
      <div class="w-full">
        <!-- <label for="{{ upgrade.id }}" class="package-card bg-center bg-cover bg-no-repeat bg-opacity-10" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" [ngStyle]="{'background-image': 'url('+upgrade.urlImage+')'}"> -->
        <label
          for="{{ upgrade.id }}"
          class="package-card relative overflow-hidden"
          [ngClass]="{
            checked:
              upgradeSelected !== null && upgrade.id === upgradeSelected()?.id,
          }">
          <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="deselectUpgrade(upgrade.id)"> -->
          <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="toggleUpgrade(upgrade.id)"> -->
          <div class="w-full h-full absolute top-0 left-0 opacity-10">
            <img
              [ngSrc]="upgrade.urlImage"
              class="w-full h-full object-center object-cover"
              width="320"
              height="110"
              [priority]="true"
              alt="" />
          </div>
          <div class="package-radio">
            <!-- <input type="radio" class="btn-radio" name="package" id="{{ upgrade.id }}"
                                   [checked]="upgradeSelected != null && upgrade.id === upgradeSelected.id"> -->
            <input
              type="checkbox"
              class="btn-radio"
              #radioButton
              name="{{ upgrade.id }}"
              id="{{ upgrade.id }}"
              [value]="upgrade.buyerTypeId"
              (change)="onChangeUpgrade(radioButton)"
              [checked]="
                upgradeSelected !== null && upgrade.id === upgradeSelected()?.id
              " />
          </div>
          <div class="w-full">
            <div class="package-header">
              <h5 class="mr-2 font-bold">{{ upgrade.name }}</h5>
              <div>
                <p class="min-w-max font-bold">
                  + ${{ upgrade.price | number: '1.2-2' }}
                </p>
                <h6 class="font-bold text-right">Per ticket</h6>
                <!-- asumimos que es por ticket me suena que lo ha dicho el cliente -->
                <!-- <h6 class="font-bold text-right">{{ package.priceType === 'ticket'? 'Per ticket' : 'Total' }}</h6> -->
              </div>
            </div>
            <div class="package-body">
              <p>{{ upgrade.description }}</p>
            </div>
          </div>
        </label>
      </div>
    }
  </div>
  @if (upgradeSelected()) {
    <div class="box-footer-center md:box-footer">
      <h5 class="font-bold text-main">
        Extra Cost (${{ upgradeSelected()?.price | number: '1.2-2' }} x
        {{ totalTransactionSeats() }} Seats): ${{
          (upgradeSelected()?.price || 0) * totalTransactionSeats()
            | number: '1.2-2'
        }}
      </h5>
    </div>
  }
</div>

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  SaleEvent,
  TransactionModel,
  TransactionWrapper,
} from '@core/models';
import { BaseConnectionService } from '@core/services/base-connection.service';
import { Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService extends BaseConnectionService {
  private http = inject(HttpClient);
  eventSubject: Subject<SaleEvent> = new Subject<SaleEvent>();
  token: string;

  // TRANSACTIONS

  // Get transaction by ID
  // Devuelve la transacion, junto la tdcTransaction ademas de la informacion del Evento
  getTransactionById(transactionId: string, brainTree = false) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}?token=${this.token}&braintree=${brainTree}`;
    return this.sendRequest<TransactionWrapper>(this.http, endpoint).pipe(
      tap(data =>
        this.eventSubject.next(data.data?.transaction?.event as SaleEvent)
      )
    );
  }

  editTransactionBuyerType(
    transactionId: string,
    buyerTypeId: number,
    buyerTypeName: string
  ) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}?token=${this.token}`;
    const body = { buyerType: { id: buyerTypeId, name: buyerTypeName } };
    const observable = this.http.patch<ApiResponse<TransactionModel>>(
      endpoint,
      body,
      this.httpOptions
    );
    return this.handleApiResponse(observable);
  }

  // Delete transaction
  // Cancela la transaccion y cancela el cartId
  deleteTransaction(transactionId: string) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}?token=${this.token}`;
    const observable = this.http.delete<
      ApiResponse<Pick<TransactionModel, 'id'>>
    >(endpoint, this.httpOptions);
    return this.handleApiResponse(observable);
  }

  // Add Rainout Insurance to cart
  // Añade el evento 'Rainout Insurance' al carro. Se edita el precio del evento sera el 10% del total del balance.
  addRainoutInsuranceToTransaction(transactionId: string) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}/add/rainout-insurance?token=${this.token}`;
    return this.sendRequest<TransactionModel>(this.http, endpoint, {});
  }

  // delete rainout insurance
  deleteRainoutInsuranceToTransaction(transactionId: string) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}/delete/rainout-insurance?token=${this.token}`;
    const observable = this.http.delete<ApiResponse<TransactionModel>>(
      endpoint,
      {}
    );
    return this.handleApiResponse(observable);
  }
}

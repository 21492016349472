<!-- Cart -->
<div class="box-white h-auto w-full md:flex md:flex-col md:justify-between">
  <div>
    <div class="box-header-center md:box-header">
      <div class="w-full text-center md:text-left">
        <h3 class="mb-1 font-bold">Your Ticket Details</h3>
        <p class="font-semibold text-gray-400">
          {{ transaction().transaction.event?.description }} -
          {{ transaction().transaction.event?.date | date: 'short' }}
        </p>
      </div>
    </div>
    <div class="box-body md:m-2">
      @for (seat of seatsByPrice(); track $index) {
        <h4 class="font-bold text-main-dark text-center md:text-left">
          <span class="underline">{{ seat.quantity }} Seats</span> x
          <span class="underline">{{
            transaction().transaction.buyerTypeName
          }}</span>
          at
          <span class="text-red">${{ seat.price | number: '1.2-2' }}</span>
        </h4>
      }
      <div class="w-full md:px-4">
        <table class="w-full table-fixed border-separate">
          @for (section of seatsForTable(); track section.id) {
            <tr class="mb-3 flex flex-wrap md:mb-0 md:flex-nowrap">
              <td class="td w-full mb-1 md:w-16 md:mb-0">
                <div class="flex justify-center items-center">
                  <img
                    ngSrc="/assets/img/chair-blue-icon.svg"
                    alt="main chair icon"
                    width="12"
                    height="12"
                    [priority]="false"
                    class="mr-1" />
                  <p class="font-bold text-center text-main">
                    {{ section.totalSectionSeats }}
                  </p>
                </div>
              </td>
              <td class="td flex w-full mb-1 md:w-min md:mb-0 md:mx-2">
                <p class="flex text-center items-center">
                  Section
                  <span class="ml-2 font-bold text-main">{{ section.id }}</span>
                </p>
              </td>
              <td class="p-0 w-full md:w-auto">
                @for (row of section.rows; track row.id) {
                  <div class="td-lines flex">
                    <p class="mr-10 md:mr-5 lg:mr-10">
                      Row<span class="ml-2 font-bold text-main">{{
                        row.id
                      }}</span>
                    </p>
                    <p>
                      Seats<span class="ml-2 font-bold text-main">{{
                        row.seatsRanges.join(', ')
                      }}</span>
                    </p>
                  </div>
                }
              </td>
            </tr>
          }
        </table>
      </div>
    </div>
  </div>
  <div class="box-footer-center md:box-footer">
    <h5 class="font-bold">
      @if (transaction().transaction.tdcTransaction?.totalPriceSeats) {
        Price: ${{
          transaction().transaction.tdcTransaction?.totalPriceSeats
            | number: '1.2-2'
        }}
      }
    </h5>
  </div>
</div>

@if (transaction()) {
  <!-- SUMMARY -->
  <div class="box-white w-full px-6 py-4 shadow-lg md:box-summary md:flex">
    <div
      class="m-auto border-gray-200 pb-3 md:pb-0 md:w-2/3 md:pr-3 md:border-r">
      <div class="m-auto">
        <h5 class="mb-2 font-bold text-center lg:text-right">Cart Summary</h5>
        <div class="text-gray-500 px-[10%] sm:px-[20%] lg:px-0">
          <div
            class="flex md:block lg:flex justify-between lg:justify-end items-center mb-2 text-center md:text-left md:sum-list-item">
            <h5 class="text-nowrap text-left lg:text-right">Seats:</h5>
            <div class="flex justify-end w-min md:w-full lg:w-min">
              <h5
                class="min-w-24 px-2 py-1 bg-main-off-white rounded-lg mt-1 md:mt-0 md:ml-4 md:text-right">
                @if (
                  transaction().transaction.tdcTransaction?.totalPriceSeats
                ) {
                  ${{
                    transaction().transaction.tdcTransaction?.totalPriceSeats
                      | number: '1.2-2'
                  }}
                }
              </h5>
            </div>
          </div>

          <div
            class="flex md:block lg:flex justify-between lg:justify-end items-center mb-2 text-center md:text-left md:sum-list-item">
            <h5 class="text-nowrap font-normal text-left lg:text-right">
              Per ticket fees:
            </h5>
            <div class="flex justify-end w-min md:w-full lg:w-min">
              <h5
                class="min-w-24 px-2 py-1 bg-main-off-white rounded-lg mt-1 md:mt-0 md:ml-4 md:text-right">
                @if (transaction().transaction.tdcTransaction?.serviceCharges) {
                  ${{
                    transaction().transaction.tdcTransaction?.serviceCharges
                      | number: '1.2-2'
                  }}
                }
              </h5>
            </div>
          </div>

          @if (
            isProduction() &&
            transaction().transaction.tdcTransaction?.amusementTax
          ) {
            <div class="mb-2 text-center md:text-left md:sum-list-item">
              <h5
                class="text-nowrap font-normal text-left lg:text-right lg:mr-10">
                Amusement tax (12% of seat cost):
              </h5>
              <div class="flex justify-end w-full">
                <h5
                  class="min-w-24 px-2 py-1 bg-main-off-white rounded-lg mt-1 md:mt-0 md:ml-4 md:text-right">
                  ${{
                    transaction().transaction.tdcTransaction?.amusementTax
                      | number: '1.2-2'
                  }}
                </h5>
                <div class="min-w-max">
                  <h5
                    class="min-w-24 px-2 py-1 bg-main-off-white rounded-lg mt-1 md:mt-0 md:ml-4 md:text-right">
                    BENEFIT
                  </h5>
                </div>
              </div>
            </div>
          }
          @if (
            rainoutValue() &&
            transaction().transaction.tdcTransaction?.insuranceRainoutPrice
          ) {
            <div class="mb-2 text-center md:mb-0 md:text-left md:sum-list-item">
              <h5 class="text-right">
                <span class="mr-1 font-normal">Insurance:</span>Rainout
                Insurance:
              </h5>
              <h5
                class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                ${{
                  transaction().transaction.tdcTransaction
                    ?.insuranceRainoutPrice | number: '1.2-2'
                }}
              </h5>
            </div>
          }
          @if (acquiredUpsells) {
            <div>
              @for (upsell of acquiredUpsells; track upsell.id) {
                <div
                  class="mb-2 text-center md:mb-0 md:text-left md:sum-list-item">
                  <h5 class="text-right">
                    <span class="mr-1 font-normal">By purchasing</span
                    >{{ upsell.goal }} tickets
                    <span class="mx-1 font-normal inline-block"
                      >you receive:</span
                    >{{ upsell.name }}.
                  </h5>
                  <div class="min-w-max">
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                      BENEFIT
                    </h5>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
    <div class="pt-3 md:pt-0 md:w-1/3 md:pl-3 md:m-auto">
      <div class="text-center">
        <h4 class="font-bold text-sm lg:text-base">Ticket Total:</h4>
        <h4 class="font-bold text-sm lg:text-base">
          @if (transaction().transaction.tdcTransaction?.balance) {
            ${{
              transaction().transaction.tdcTransaction?.balance
                | number: '1.2-2'
            }}
          }
        </h4>
      </div>
    </div>
  </div>
}
